import { useStateMachine } from 'little-state-machine';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import { Button, Expand, InputNumber } from '@/components';
import { updateFormActionLander } from '@/hooks';
import { OutletContext } from '@/model';
import { AgeValidation } from '@/validations';

interface AgeForm {
	age: string;
}

export const Age = () => {
	const { handleNext, versionId }: OutletContext = useOutletContext();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<AgeForm>({
		resolver: AgeValidation,
	});
	const { actions, state } = useStateMachine({ updateFormActionLander });

	const onSubmit = (data: AgeForm) => {
		actions.updateFormActionLander({ lander: { ...state.lander, ...data } });
		handleNext();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InputNumber
				{...register('age')}
				placeholder="Indsæt din alder"
				min="1"
			/>
			<Expand expanded={Boolean(errors?.age)}>
				<p
					className={`pandora__form--error pandora__form--error--v${versionId}`}
				>
					{errors.age?.message}
				</p>
			</Expand>
			<Button>Næste</Button>
		</form>
	);
};
