export class LocalStorage {
	static setItem(key: string, value?: string | null) {
		if (value) {
			localStorage.setItem(key, value);
			window.dispatchEvent(new Event('storage'));
		}
	}

	static getItem(key: string) {
		return localStorage.getItem(key);
	}

	static clear() {
		localStorage.clear();
		window.dispatchEvent(new Event('storage'));
	}

	static removeItem(key: string) {
		localStorage.removeItem(key);
		window.dispatchEvent(new Event('storage'));
	}
}

export enum LocalStorageItems {
	Email = 'dk.lokalflirt.email',
	AccessToken = 'dk.lokalflirt.accessToken',
	LanderId = 'dk.lokalflirt.landerId',
	Reactivate = 'dk.lokalflirt.reactivate',
}
