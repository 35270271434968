import { FC } from 'react';

interface IconProps {
	color?: string;
	size?: number;
	className?: string;
}

export const EyeOpened: FC<IconProps> = ({
	color = 'currentColor',
	size = 26,
	className,
}) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox={`0 0 26 26`}
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M24.6041 12.5227C24.3923 12.2313 19.3483 5.38623 13 5.38623C6.65167 5.38623 1.60767 12.2307 1.39642 12.5221C1.19004 12.8065 1.19004 13.1922 1.39642 13.4771C1.60767 13.7685 6.65167 20.6136 13 20.6136C19.3483 20.6136 24.3923 13.7685 24.6041 13.4776C24.811 13.1927 24.811 12.8071 24.6041 12.5227ZM13 18.9886C8.37959 18.9886 4.31275 14.496 3.08696 12.9999C4.31275 11.5038 8.37959 7.01123 13 7.01123C17.6204 7.01123 21.6872 11.5038 22.9136 12.9999C21.6872 14.496 17.6204 18.9886 13 18.9886ZM15.2864 10.2276C14.9137 10.4768 14.8135 10.9816 15.0627 11.3549C15.3346 11.7616 15.4787 12.2372 15.4787 12.7296C15.4787 14.0957 14.3666 15.2077 13.0005 15.2077C11.6345 15.2077 10.5224 14.0957 10.5224 12.7296C10.5224 11.3635 11.6339 10.2515 13.0005 10.2515C13.4496 10.2515 13.813 9.88802 13.813 9.43898C13.813 8.98994 13.4496 8.62648 13.0005 8.62648C10.738 8.62648 8.89742 10.4671 8.89742 12.7296C8.89742 14.9921 10.738 16.8327 13.0005 16.8327C15.2631 16.8327 17.1037 14.9921 17.1037 12.7296C17.1037 11.9149 16.8653 11.1274 16.4141 10.4519C16.1639 10.0776 15.659 9.97848 15.2864 10.2276Z"
				fill={color}
			/>
		</svg>
	);
};
