import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { password } from './common';

const schema = yup.object().shape({
	password,
});

export const PasswordValidation = yupResolver(schema);
