import { useMutation } from '@tanstack/react-query';

import { City, PaginationList } from '@/model';

import { axiosInstance } from '../axios';

export const useGetCityByZipCode = () => {
	return useMutation({
		mutationFn: async (zip_code: string): Promise<PaginationList<City>> => {
			const { data } = await axiosInstance.get('zip-codes', {
				params: { 'filter[search]': zip_code },
			});
			return data;
		},
		onError: (error) => error,
	});
};
