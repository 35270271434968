import { useOutletContext } from 'react-router-dom';

import match1Src from '@/assets/images/Lander2/version1/matches/match1.png';
import match2Src from '@/assets/images/Lander2/version1/matches/match2.png';
import match3Src from '@/assets/images/Lander2/version1/matches/match3.png';
import match4Src from '@/assets/images/Lander2/version1/matches/match4.png';
import match5Src from '@/assets/images/Lander2/version1/matches/match5.png';
import { AnimatedCounter, Button } from '@/components';
import { OutletContext } from '@/model';
import { generateRandomIntegerRange } from '@/utility';

const IMAGES = [match1Src, match2Src, match3Src, match4Src, match5Src];

export const Matches = () => {
	const { handleNext }: OutletContext = useOutletContext();

	return (
		<div className="lander2__wrapper__container__matches__wrapper">
			<div className="flex flex-col gap-3.5 items-center">
				<AnimatedCounter value={generateRandomIntegerRange(20100, 30123)} />
				<div className="flex justify-center items-center translate-x-[40px]">
					{IMAGES.map((image, index) => {
						const middleIndex = Math.floor(IMAGES.length / 2);
						const isMiddle = index === middleIndex;
						const isFirst = index === 0;
						const isLast = index === IMAGES.length - 1;
						return (
							<img
								key={image}
								src={image}
								alt="match"
								className={`lander2__wrapper__container__matches__wrapper--image ${isMiddle ? 'middle' : ''}`}
								style={{
									zIndex: isFirst || isLast ? 0 : index,
									transform: `translateX(${index * -20}px)`,
								}}
							/>
						);
					})}
				</div>
			</div>
			<Button onClick={handleNext}>Næste</Button>
		</div>
	);
};
