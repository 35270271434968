import { useOutletContext } from 'react-router-dom';

import { RadioGroupVertical } from '@/components';
import { OutletContext } from '@/model';

import { Content } from '../content';

export const Preference = () => {
	const { landerId, versionId }: OutletContext = useOutletContext();
	const preference = Content[landerId][versionId].preference;

	return (
		<RadioGroupVertical
			options={preference?.options ?? []}
			name={preference?.name ?? ''}
		/>
	);
};
