interface IGoogle {
	size?: number;
	color?: string;
}

export const Google = ({ size = 25, color = 'currentColor' }: IGoogle) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox={`0 0 ${size} ${size}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_132_25)">
				<path
					d="M11.5 0C5.14841 0 0 5.14912 0 11.5C0 17.8509 5.14841 23 11.5 23C17.8509 23 23 17.8509 23 11.5C23 5.14912 17.8516 0 11.5 0ZM11.6243 17.6784C8.211 17.6784 5.44597 14.9133 5.44597 11.5C5.44597 8.08666 8.211 5.32162 11.6243 5.32162C13.2926 5.32162 14.6869 5.93616 15.7557 6.93163L14.0142 8.67316V8.66956C13.3659 8.05144 12.5429 7.73519 11.6243 7.73519C9.58597 7.73519 7.92925 9.45731 7.92925 11.4957C7.92925 13.5341 9.58597 15.2605 11.6243 15.2605C13.4737 15.2605 14.7329 14.2025 14.9917 12.7506H11.6243V10.3407H17.4354C17.5123 10.7532 17.5547 11.1866 17.5547 11.638C17.5547 15.1685 15.1915 17.6784 11.6243 17.6784Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_132_25">
					<rect width="23" height="23" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
