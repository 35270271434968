import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
	age,
	city,
	email,
	gender,
	password,
	preference,
	username,
	womanType,
	zipCode,
} from './common';

const schema = yup.object().shape({
	gender,
	womanType,
	age,
	preference,
	username,
	email,
	password,
	city,
	zipCode,
});

export const Lander1Validation = yupResolver(schema);
