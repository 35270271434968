import { createBrowserRouter } from 'react-router-dom';

import { Lander } from '@/pages/Lander';
import { Redirect } from '@/pages/Redirect';
import {
	Age,
	Email,
	Gender,
	Matches,
	Password,
	Preference,
	RelationshipType,
	Success,
	Username,
	UsernamePassword,
	WomanPreference,
	WomanType,
	ZipCode,
} from '@/pages/steps';

export const router = createBrowserRouter([
	{
		path: '/:landerId/:versionId',
		element: <Lander />,
		children: [
			{ path: 'gender', element: <Gender /> },
			{ path: 'woman-type', element: <WomanType /> },
			{ path: 'age', element: <Age /> },
			{ path: 'preference', element: <Preference /> },
			{ path: 'zip-code', element: <ZipCode /> },
			{ path: 'user', element: <UsernamePassword /> },
			{ path: 'username', element: <Username /> },
			{ path: 'password', element: <Password /> },
			{ path: 'email', element: <Email /> },
			{ path: 'matches', element: <Matches /> },
			{ path: 'relationship-type', element: <RelationshipType /> },
			{ path: 'woman-preference', element: <WomanPreference /> },
			{ path: 'success', element: <Success /> },
			{ path: '*', element: <Redirect /> },
		],
	},
	{
		path: '*',
		element: (
			<div className="grid items-center justify-center h-screen text-9xl">
				404
			</div>
		),
	},
	{
		path: '/',
		element: <Redirect />,
	},
]);
