import { useOutletContext } from 'react-router-dom';

import { RadioGroupVertical } from '@/components';
import { OutletContext } from '@/model';

import { Content } from '../content';

export const WomanType = () => {
	const { landerId, versionId }: OutletContext = useOutletContext();
	const womanType = Content[landerId][versionId].womanType;

	return (
		<RadioGroupVertical
			options={womanType?.options ?? []}
			name={womanType?.name ?? ''}
		/>
	);
};
