import './style.scss';

import { forwardRef, Ref, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { EyeClosed, EyeOpened } from '@/icons';
import { OutletContext } from '@/model';

interface IInputPassword {
	placeholder?: string;
}

export const InputPassword = forwardRef(
	({ placeholder, ...props }: IInputPassword, ref: Ref<HTMLInputElement>) => {
		const { versionId }: OutletContext = useOutletContext();
		const [showPassword, setShowPassword] = useState(false);

		const togglePasswordVisibility = () => {
			setShowPassword((prev) => !prev);
		};

		return (
			<div className="relative">
				<input
					className={`pandora__input pandora__input--v${versionId}`}
					type={showPassword ? 'text' : 'password'}
					ref={ref}
					{...props}
					placeholder={placeholder}
				/>
				<div className="absolute right-6 top-[50%] -translate-y-1/2 cursor-pointer">
					{showPassword ? (
						<div onClick={togglePasswordVisibility}>
							<EyeOpened
								className={`pandora__input__password--icon pandora__input__password--icon--v${versionId}`}
							/>
						</div>
					) : (
						<div onClick={togglePasswordVisibility}>
							<EyeClosed
								className={`pandora__input__password--icon pandora__input__password--icon--v${versionId}`}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
);
