import { useQuery } from '@tanstack/react-query';

import { User, UserRegistration } from '@/model';
import { axiosInstance } from '@/service';

const key = ['REGISTER_USER'];

export const useRegister = (registerData: Partial<User>) =>
	useQuery({
		queryKey: [...key, registerData],
		queryFn: () =>
			new Promise<UserRegistration>((resolve) => {
				setTimeout(async () => {
					const { data } = await axiosInstance.post(
						'auth/member/register',
						registerData,
						{
							headers: { doNotAddUserTokenAuthorization: true },
						}
					);
					resolve(data);
				}, 1500);
			}),
		enabled: Object.values(registerData).length > 0,
	});
