import { useEffect, useState } from 'react';

type Props = {
	width: number;
	height: number;
};

export const useWindowSize = (): Props => {
	const [windowSize, setWindowSize] = useState<Props>({
		width: 0,
		height: 0,
	});

	useEffect(() => {
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener('resize', handleResize);

		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowSize;
};
