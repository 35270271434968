import './style.scss';

import { useStateMachine } from 'little-state-machine';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import { updateFormActionLander } from '@/hooks';
import { OutletContext } from '@/model';

import { Expand } from '../Expand';
import { IRadio, Radio } from './Radio';

interface IRadioOptions extends IRadio {
	name: string;
}

interface IRadioGroup {
	options: IRadioOptions[];
	name: string;
}

export const RadioGroupHorizontal = ({ options, name }: IRadioGroup) => {
	const { handleNext, versionId }: OutletContext = useOutletContext();
	const {
		handleSubmit,
		setValue,
		register,
		setError,
		clearErrors,
		formState: { errors },
		watch,
	} = useForm<{
		[key: string]: string;
	}>();
	const { actions, state } = useStateMachine({ updateFormActionLander });
	const value = watch(name);
	const onSubmit = handleSubmit((data) => {
		if (!data[name]) {
			setError(name, { message: 'Du skal vælge mindst en mulighed' });
			return;
		}
		actions.updateFormActionLander({ lander: { ...state.lander, ...data } });
		handleNext();
	});

	const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		clearErrors();
		setValue(e.target.name, e.target.value);
		onSubmit();
	};

	return (
		<form className="pandora__radio__group__horizontal">
			{options?.map((option) => {
				return (
					<Radio
						key={option.value?.toString()}
						{...register(option.name)}
						onChange={handleRadioChange}
						checked={value === option.value}
						{...option}
					/>
				);
			})}
			<Expand expanded={Boolean(errors[name])}>
				<p
					className={`pandora__form--error pandora__form--error--v${versionId}`}
				>
					{errors[name]?.message}
				</p>
			</Expand>
		</form>
	);
};
