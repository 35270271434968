import { useStateMachine } from 'little-state-machine';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import { Button, Expand, InputText } from '@/components';
import { updateFormActionLander } from '@/hooks';
import { OutletContext } from '@/model';
import { UsernameValidation } from '@/validations';

interface UsernameForm {
	username: string;
}

export const Username = () => {
	const { handleNext, versionId }: OutletContext = useOutletContext();
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<UsernameForm>({ resolver: UsernameValidation });
	const { actions, state } = useStateMachine({ updateFormActionLander });

	const onSubmit = (data: UsernameForm) => {
		actions.updateFormActionLander({ lander: { ...state.lander, ...data } });
		handleNext();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
			<div className="flex flex-col gap-3.5">
				<InputText
					{...register('username')}
					placeholder="Indtast dit brugernavn"
				/>
				<Expand expanded={Boolean(errors.username)}>
					<p
						className={`pandora__form--error pandora__form--error--v${versionId}`}
					>
						{errors.username?.message}
					</p>
				</Expand>
			</div>
			<Button>Næste</Button>
		</form>
	);
};
