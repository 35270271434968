// Version 1
import version1Bg from './version1/bg.png';
import version1GenderFemale from './version1/gender/female.png';
import version1GenderMale from './version1/gender/male.png';
import version1Host from './version1/host.png';
import version1PreferenceAnal from './version1/preference/anal.png';
import version1PreferenceOral from './version1/preference/oral.png';
import version1PreferenceVaginal from './version1/preference/vaginal.png';
import version1WomanTypeDominant from './version1/womanType/dominant.png';
import version1WomanTypeNormal from './version1/womanType/normal.png';
import version1WomanTypeSubmissive from './version1/womanType/submissive.png';
// Version 2
import version2Bg from './version2/bg.png';
import version2GenderFemale from './version2/gender/female.png';
import version2GenderMale from './version2/gender/male.png';
import version2Host from './version2/host.png';
import version2PreferenceDoggy from './version2/preference/doggy.png';
import version2PreferenceMissionary from './version2/preference/missionary.png';
import version2PreferenceOral from './version2/preference/oral.png';
import version2WomanTypeLarge from './version2/womanType/large.png';
import version2WomanTypeNormal from './version2/womanType/normal.png';
import version2WomanTypeSlim from './version2/womanType/slim.png';
// Version 3
import version3Bg from './version3/bg.png';
import version3GenderFemale from './version3/gender/female.png';
import version3GenderMale from './version3/gender/male.png';
import version3Host from './version3/host.png';
import version3PreferenceAtTheirs from './version3/preference/atTheirs.png';
import version3PreferenceAtYours from './version3/preference/atYours.png';
import version3PreferenceInPublic from './version3/preference/inPublic.png';
import version3WomanTypeBlonde from './version3/womanType/blonde.png';
import version3WomanTypeBrown from './version3/womanType/brown.png';
import version3WomanTypeRed from './version3/womanType/red.png';
// Version 4
import version4Bg from './version4/bg.png';
import version4GenderFemale from './version4/gender/female.png';
import version4GenderMale from './version4/gender/male.png';
import version4Host from './version4/host.png';
import version4PreferenceDinner from './version4/preference/dinner.png';
import version4PreferenceHome from './version4/preference/home.png';
import version4PreferenceHotel from './version4/preference/hotel.png';
import version4WomanTypeDominant from './version4/womanType/dominant.png';
import version4WomanTypeNormal from './version4/womanType/normal.png';
import version4WomanTypeSubmissive from './version4/womanType/submissive.png';

export const Lander1Version1 = {
	bg: version1Bg,
	gender: {
		female: version1GenderFemale,
		male: version1GenderMale,
	},
	host: version1Host,
	preference: {
		anal: version1PreferenceAnal,
		oral: version1PreferenceOral,
		vaginal: version1PreferenceVaginal,
	},
	womanType: {
		dominant: version1WomanTypeDominant,
		normal: version1WomanTypeNormal,
		submissive: version1WomanTypeSubmissive,
	},
};

export const Lander1Version2 = {
	bg: version2Bg,
	gender: {
		female: version2GenderFemale,
		male: version2GenderMale,
	},
	host: version2Host,
	preference: {
		doggy: version2PreferenceDoggy,
		missionary: version2PreferenceMissionary,
		oral: version2PreferenceOral,
	},
	womanType: {
		large: version2WomanTypeLarge,
		normal: version2WomanTypeNormal,
		slim: version2WomanTypeSlim,
	},
};

export const Lander1Version3 = {
	bg: version3Bg,
	gender: {
		female: version3GenderFemale,
		male: version3GenderMale,
	},
	host: version3Host,
	preference: {
		atTheirs: version3PreferenceAtTheirs,
		atYours: version3PreferenceAtYours,
		inPublic: version3PreferenceInPublic,
	},
	womanType: {
		blonde: version3WomanTypeBlonde,
		brown: version3WomanTypeBrown,
		red: version3WomanTypeRed,
	},
};

export const Lander1Version4 = {
	bg: version4Bg,
	gender: {
		female: version4GenderFemale,
		male: version4GenderMale,
	},
	host: version4Host,
	preference: {
		dinner: version4PreferenceDinner,
		home: version4PreferenceHome,
		hotel: version4PreferenceHotel,
	},
	womanType: {
		dominant: version4WomanTypeDominant,
		normal: version4WomanTypeNormal,
		submissive: version4WomanTypeSubmissive,
	},
};
