import * as yup from 'yup';

export const age = yup
	.string()
	.required('Påkrævet')
	.min(1, 'Kan ikke være 0')
	.test(
		'age',
		'Du skal være mindst 18 år gammel',
		(value) => Number(value) >= 18
	)
	.test('age range', 'Ikke større end 100', (value) => Number(value) < 100);
