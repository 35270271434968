import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { gender } from './common';

const schema = yup.object().shape({
	gender,
});

export const GenderValidation = yupResolver(schema);
