import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { city, zipCode } from './common';

const schema = yup.object().shape({
	zipCode,
	city,
});

export const ZipCodeValidation = yupResolver(schema);
