import { useInView, useMotionValue, useSpring } from 'framer-motion';
import { useEffect, useRef } from 'react';

export const AnimatedCounter = ({
	value = 0,
	direction = 'up',
}: {
	value: number;
	direction?: 'up' | 'down';
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const motionValue = useMotionValue(direction === 'down' ? value ?? 0 : 0);
	const springValue = useSpring(motionValue, {
		damping: 100,
		stiffness: 100,
	});
	const isInView = useInView(ref, { once: true, margin: '-100px' });
	useEffect(() => {
		if (isInView) {
			motionValue.set(direction === 'down' ? 0 : value ?? 0);
		}
	}, [motionValue, isInView, direction, value]);

	useEffect(
		() =>
			springValue.on('change', (latest) => {
				if (ref.current) {
					ref.current.textContent =
						latest.toFixed(0).padStart(4, '0') ?? '0000';
				}
			}),
		[springValue, isInView]
	);

	return (
		<div>
			<div
				ref={ref}
				className={'px-6 py-1 text-5xl font-bold text-white tabular-nums'}
			>
				{value.toFixed(0).padStart(4, '0')}
			</div>
		</div>
	);
};
