import * as yup from 'yup';

export const username = yup
	.string()
	.required('Påkrævet')
	.min(6, 'Minimum 6 tegn')
	.max(16, 'Maksimalt 16 tegn')
	.test(
		'special characters',
		'The username must only contain letters, numbers, dashes and underscores.',
		(val) => /^[a-zA-Z0-9_-]+$/.test(val)
	);
