import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { age } from './common';

const schema = yup.object().shape({
	age,
});

export const AgeValidation = yupResolver(schema);
