import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { email } from './common';

const schema = yup.object().shape({
	email,
});

export const EmailValidation = yupResolver(schema);
