import './style.scss';

import { ButtonHTMLAttributes, ReactNode } from 'react';
import { useOutletContext } from 'react-router-dom';

import { OutletContext } from '@/model';

import { Spinner } from '../spinner';

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: ReactNode;
	disabled?: boolean;
	className?: string;
	onClick?: () => void;
	loading?: boolean;
	variant?: 'outline';
}

export const Button = ({
	children,
	disabled,
	className,
	onClick,
	loading,
	variant,
	...props
}: IButton) => {
	const { versionId }: OutletContext = useOutletContext();

	return (
		<button
			className={`pandora__button pandora__button--v${versionId} ${disabled ? 'pandora__button--disabled' : ''} ${className ? className : ''} ${variant ? `pandora__button__${variant}` : ''}`}
			disabled={disabled}
			onClick={onClick}
			{...props}
		>
			{loading ? <Spinner /> : children}
		</button>
	);
};
