import './style.scss';

interface ISpinner {
	size?: number;
	color?: string;
}

export const Spinner = ({ size = 20, color }: ISpinner) => (
	<div
		className="donut-spinner"
		style={{ width: size, height: size, borderLeftColor: color }}
	/>
);
