import './style.scss';

import { forwardRef, Ref } from 'react';
import { useOutletContext } from 'react-router-dom';

import { OutletContext } from '@/model';

interface IInputNumber {
	placeholder?: string;
	min?: string;
}

export const InputNumber = forwardRef(
	(
		{ placeholder, min, ...props }: IInputNumber,
		ref: Ref<HTMLInputElement>
	) => {
		const { versionId }: OutletContext = useOutletContext();

		return (
			<input
				className={`pandora__input pandora__input--v${versionId}`}
				type="number"
				ref={ref}
				{...props}
				placeholder={placeholder}
				min={min}
			/>
		);
	}
);
