import './style.scss';

import { forwardRef, InputHTMLAttributes, Ref } from 'react';
import { useOutletContext } from 'react-router-dom';

import { OutletContext } from '@/model';

export interface IRadio extends InputHTMLAttributes<HTMLInputElement> {
	image: string;
	label: string;
}

export const Radio = forwardRef(
	({ image, onChange, ...props }: IRadio, ref: Ref<HTMLInputElement>) => {
		const { versionId }: OutletContext = useOutletContext();
		return (
			<label
				className={`pandora__radio pandora__radio__item ${props.checked ? 'pandora__radio--checked' : ''}`}
				htmlFor={`pandora__radio--${props.value}`}
			>
				<input
					id={`pandora__radio--${props.value}`}
					type="radio"
					className="pandora__radio--input"
					ref={ref}
					checked={props.checked}
					{...props}
					onChange={(e) => {
						onChange?.(e);
					}}
				/>
				<img src={image} alt="" className="pandora__radio--image" />
				<div
					className={`pandora__radio--overlay pandora__radio--overlay--v${versionId}`}
				/>
				<p className="pandora__radio--label">{props.label}</p>
			</label>
		);
	}
);

Radio.displayName = 'Radio';
