import { format, subYears } from 'date-fns';
import { useStateMachine } from 'little-state-machine';
import { useEffect } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { Spinner } from '@/components';
import { updateFormActionLander } from '@/hooks';
import { Gender, OutletContext, Seeking } from '@/model';
import { useRegister } from '@/service';
import { LocalStorage, LocalStorageItems } from '@/utility';

export const Success = () => {
	const { versionId }: OutletContext = useOutletContext();
	const [searchParams] = useSearchParams();
	const { state } = useStateMachine({ updateFormActionLander });
	const click_id = searchParams.get('clickId') ?? '';
	const affiliate_id = searchParams.get('affiliate') ?? '';
	const source_id = searchParams.get('source') ?? '';
	const lander_id = LocalStorage.getItem(LocalStorageItems.LanderId);

	const { username, password, gender, city, zipCode, email, age } =
		state.lander ?? {};
	const birthday = format(subYears(new Date(), Number(age)), 'yyyy-MM-dd');
	const registerData = {
		click_id,
		affiliate_id,
		source_id,
		lander_id,
		username,
		password,
		password_confirmation: password,
		email,
		profile: {
			gender,
			seeking: gender === Gender.Male ? Seeking.Women : Seeking.Men,
			city,
			zip_code: zipCode,
			birthday,
		},
	};
	const { data, isError, refetch } = useRegister(registerData);

	useEffect(() => {
		if (data?.access_token) {
			const lander_id = LocalStorage.getItem(LocalStorageItems.LanderId);
			if (!lander_id?.includes('_typed_email')) {
				LocalStorage.setItem(LocalStorageItems.AccessToken, data.access_token);

				window.location.href = `${import.meta.env.VITE_REDIRECT_URL}?accessToken=${data?.access_token}`;
				return;
			}
			LocalStorage.setItem(LocalStorageItems.Email, data?.user?.email);
			window.location.href = `${import.meta.env.VITE_REDIRECT_URL}/email-verification?email=${email}`;
		}
	}, [data, data?.access_token, email]);

	return (
		<div className="flex items-center justify-center">
			<div className="absolute top-[50%] -translate-y-1/2 md:static md:top-auto md:translate-y-0">
				{isError ? (
					<div className="flex justify-center items-center gap-0.5">
						<p
							className={`pandora__form--error pandora__form--error--v${versionId} !mt-0`}
						>
							Noget gik galt.
						</p>
						<button
							onClick={() => refetch()}
							className={`pandora__button__email--error pandora__button__email--error--v${versionId} underline cursor-pointer`}
						>
							Retry
						</button>
					</div>
				) : (
					<Spinner size={100} />
				)}
			</div>
		</div>
	);
};
