import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { password, username } from './common';

const schema = yup.object().shape({
	username,
	password,
});

export const UsernamePasswordValidation = yupResolver(schema);
