import './style.scss';

import { forwardRef, Ref } from 'react';
import { useOutletContext } from 'react-router-dom';

import { OutletContext } from '@/model';

interface IInputText {
	placeholder?: string;
}

export const InputText = forwardRef(
	({ placeholder, ...props }: IInputText, ref: Ref<HTMLInputElement>) => {
		const { versionId }: OutletContext = useOutletContext();

		return (
			<input
				className={`pandora__input pandora__input--v${versionId}`}
				type="text"
				ref={ref}
				{...props}
				placeholder={placeholder}
			/>
		);
	}
);
