import { useOutletContext } from 'react-router-dom';

import { RadioGroupVertical } from '@/components';
import { OutletContext } from '@/model';

import { Content } from '../content';

export const WomanPreference = () => {
	const { landerId, versionId }: OutletContext = useOutletContext();
	const womanPreference = Content[landerId][versionId].womanPreference;

	return (
		<RadioGroupVertical
			options={womanPreference?.options ?? []}
			name={womanPreference?.name ?? ''}
		/>
	);
};
