import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { womanType } from './common';

const schema = yup.object().shape({
	womanType,
});

export const WomanTypeValidation = yupResolver(schema);
