import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { preference } from './common';

const schema = yup.object().shape({
	preference,
});

export const PreferenceValidation = yupResolver(schema);
