import { ValidateUserEmailResponse } from '@/model';

import { LocalStorage, LocalStorageItems } from './LocalStorage';

export function handleAuthReactivation(
	response: ValidateUserEmailResponse,
	callback: () => void
): void {
	const email = LocalStorage.getItem(LocalStorageItems.Email) ?? '';
	const { redirect_url, auth_data } = response;
	if (redirect_url === null && auth_data === null) {
		console.log('usao ovde');
		window.location.href = `${import.meta.env.VITE_REDIRECT_URL}/email-verification?email=${email}`;
		return;
	}
	if (redirect_url) {
		window.location.href = redirect_url;
		return;
	}
	if (auth_data) {
		const { access_token } = auth_data;
		window.location.href = `${import.meta.env.VITE_REDIRECT_URL}/login?accessToken=${access_token}`;
	} else {
		callback();
	}
}
