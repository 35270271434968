import { useStateMachine } from 'little-state-machine';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import { Button, Expand, InputText } from '@/components';
import { updateFormActionLander } from '@/hooks';
import { OutletContext } from '@/model';
import { useGetCityByZipCode } from '@/service';
import { ZipCodeValidation } from '@/validations';

interface ZipCodeForm {
	zipCode: string;
	city: string;
}

export const ZipCode = () => {
	const { handleNext, versionId }: OutletContext = useOutletContext();
	const { mutate: getCities, isPending } = useGetCityByZipCode();
	const {
		register,
		watch,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<ZipCodeForm>({
		resolver: ZipCodeValidation,
	});
	const { actions, state } = useStateMachine({ updateFormActionLander });

	const [error, setError] = useState<string>('');
	const [disabled, setDisabled] = useState<boolean>(false);
	const zipCode = watch('zipCode');

	const onSubmit = (data: ZipCodeForm) => {
		actions.updateFormActionLander({ lander: { ...state.lander, ...data } });
		handleNext();
	};

	const handleCities = useCallback(() => {
		setDisabled(false);
		setError('');
		if (zipCode?.length !== 4) return;
		getCities(zipCode, {
			onSuccess: (response) => {
				if (response?.data?.length > 0) {
					setValue('city', response.data[0].city);
				} else {
					setDisabled(true);
					setError('Vi kunne ikke finde noget');
				}
			},
		});
	}, [getCities, setValue, zipCode]);

	useEffect(() => {
		handleCities();
	}, [zipCode, handleCities]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InputText
				{...register('zipCode')}
				placeholder="Indtast dit postnummer"
			/>
			<div className="hidden">
				<InputText {...register('city')} placeholder="Indtast din postkode" />
			</div>
			<Expand expanded={Boolean(errors.zipCode) && !error}>
				<p
					className={`pandora__form--error pandora__form--error--v${versionId}`}
				>
					{errors.zipCode?.message}
				</p>
			</Expand>
			<Expand expanded={Boolean(error)}>
				<p
					className={`pandora__form--error pandora__form--error--v${versionId}`}
				>
					{error}
				</p>
			</Expand>
			<Button disabled={disabled} loading={isPending}>
				Næste
			</Button>
		</form>
	);
};
