import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ValidateUserEmail, ValidateUserEmailResponse } from '@/model';

import { axiosInstance } from '../axios';

const key = ['VALIDATE_USER_EMAIL'];

export const useValidateUserEmail = () =>
	useMutation({
		mutationKey: [...key],
		mutationFn: async ({
			email,
			click_id,
			lander_id,
			affiliate_id,
			source_id,
			username,
			password,
			password_confirmation,
			gender,
			city,
			zip_code,
			birthday,
			seeking,
		}: ValidateUserEmail): Promise<ValidateUserEmailResponse> => {
			const { data } = await axiosInstance.post('auth/member/validate-email', {
				email: email || undefined,
				click_id: click_id || undefined,
				lander_id: lander_id || undefined,
				affiliate_id: affiliate_id || undefined,
				source_id: source_id || undefined,
				username: username || undefined,
				password: password || undefined,
				password_confirmation: password_confirmation || undefined,
				profile: {
					gender: gender || undefined,
					city: city || undefined,
					zip_code: zip_code || undefined,
					birthday: birthday || undefined,
					seeking: seeking || undefined,
				},
			});
			return data;
		},
		onError: (error: AxiosError<{ errors: Record<string, string[]> }>) =>
			console.log(error),
	});
