import { animate, motion, useMotionValue, useTransform } from 'framer-motion';
import { useEffect } from 'react';

import { calculateTypingDuration } from '@/utility';

import { CursorBlinker } from './CursorBlinker';

export interface IAnimTextProps {
	text: string;
	setDone: (done: boolean) => void;
	delay?: number;
	cursorBlinkerColor?: string;
}

export const AnimateTextTyping = ({
	delay = 0,
	text,
	setDone,
	cursorBlinkerColor,
}: IAnimTextProps) => {
	const count = useMotionValue(0);
	const rounded = useTransform(count, (latest) => Math.round(latest));
	const displayText = useTransform(rounded, (latest) => text.slice(0, latest));

	useEffect(() => {
		const controls = animate(count, text.length, {
			type: 'tween',
			delay: delay,
			duration: calculateTypingDuration(text),
			ease: 'easeInOut',
			onComplete: () => {
				setDone(true);
			},
		});
		return controls.stop;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<span className="">
			<motion.span>{displayText}</motion.span>
			<CursorBlinker color={cursorBlinkerColor} />
		</span>
	);
};
