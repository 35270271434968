import { FC } from 'react';

interface IconProps {
	color?: string;
	size?: number;
	className?: string;
}

export const EyeClosed: FC<IconProps> = ({
	color = 'currentColor',
	size = 26,
	className,
}) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox={`0 0 26 26`}
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M10.4845 7.43167C11.3393 7.15271 12.1853 7.01133 13 7.01133C17.621 7.01133 21.6883 11.505 22.9141 13.0005C22.4754 13.5379 21.6726 14.4598 20.6137 15.4164C20.2811 15.7175 20.2551 16.231 20.5557 16.5642C20.7166 16.7413 20.9371 16.8317 21.1586 16.8317C21.3531 16.8317 21.5481 16.7624 21.7035 16.6221C23.4764 15.0193 24.5586 13.539 24.6041 13.4767C24.8111 13.1923 24.8111 12.8066 24.6041 12.5217C24.3923 12.2314 19.3483 5.38633 13 5.38633C12.0142 5.38633 10.9986 5.55479 9.98076 5.88683C9.55393 6.02604 9.32101 6.48483 9.46076 6.91112C9.59943 7.3385 10.0593 7.57196 10.4845 7.43167ZM2.58755 2.16666C2.24955 1.87254 1.73605 1.90721 1.44139 2.24575C1.14618 2.58375 1.18193 3.09671 1.51993 3.39191L6.44205 7.68137C3.45205 9.72454 1.48526 12.4004 1.39643 12.5228C1.19005 12.8072 1.19005 13.1928 1.39643 13.4777C1.60768 13.7686 6.65168 20.6137 13 20.6137C14.9608 20.6137 16.9899 19.9501 19.0369 18.6572L23.4125 22.4705C23.5663 22.6048 23.7564 22.6704 23.9455 22.6704C24.1724 22.6704 24.3978 22.5761 24.5586 22.3914C24.8533 22.0534 24.8181 21.5399 24.4796 21.2452L2.58755 2.16666ZM10.8491 11.5212L14.4956 14.6992C14.0693 15.022 13.546 15.2073 13 15.2073C11.6339 15.2073 10.5219 14.0952 10.5219 12.7292C10.5219 12.2958 10.6427 11.8869 10.8491 11.5212ZM13 18.9887C8.37905 18.9887 4.31222 14.4955 3.08643 12.9995C3.80468 12.1214 5.50605 10.218 7.71659 8.79179L9.59943 10.433C9.14334 11.1047 8.89634 11.895 8.89634 12.7292C8.89634 14.9917 10.7369 16.8323 12.9995 16.8323C14.0173 16.8323 14.9896 16.4477 15.7354 15.7804L17.7542 17.5397C16.1173 18.499 14.521 18.9887 13 18.9887Z"
				fill={color}
			/>
		</svg>
	);
};
