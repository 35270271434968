import { AnimatePresence, motion } from 'framer-motion';
import { FC, ReactNode } from 'react';

type Props = {
	expanded: boolean;
	children: ReactNode;
	initial?: boolean;
};

const variants = {
	show: {
		opacity: 1,
		height: 'auto',
	},
	hide: {
		opacity: 0,
		height: 0,
	},
	exit: {
		opacity: 0,
		height: 0,
	},
};

export const Expand: FC<Props> = ({
	children,
	expanded,
	initial = undefined,
	...rest
}) => {
	return (
		<AnimatePresence initial={initial}>
			{expanded && (
				<motion.div
					initial="hide"
					variants={variants}
					animate="show"
					exit="exit"
					{...rest}
					style={{ overflow: 'hidden' }} // TOOO think
				>
					{children}
				</motion.div>
			)}
		</AnimatePresence>
	);
};
