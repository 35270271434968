import './style.scss';

import { useEffect, useMemo, useState } from 'react';
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';

import { Page } from '@/model';

import { config } from '../config';
import { Lander1 } from './Lander1';
import { Lander2 } from './Lander2';

interface LanderComponent {
	percent: number;
	message: string;
	done: boolean;
	setDone: React.Dispatch<React.SetStateAction<boolean>>;
	handleNext: () => void;
	landerId: string;
	versionId: string;
}

interface LanderComponents {
	[key: string]: React.ComponentType<LanderComponent>;
}

export const Lander = () => {
	const { landerId, versionId } = useParams() as {
		landerId: string;
		versionId: string;
	};
	const [searchParams] = useSearchParams();
	const location = useLocation();
	const navigate = useNavigate();
	const market = import.meta.env.VITE_MARKET;
	const [height, setHeight] = useState<number>(window.innerHeight);
	useEffect(() => {
		const handleResize = () => {
			setHeight(window.innerHeight);
		};

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	const [done, setDone] = useState<boolean>(false);
	const [message, setMessage] = useState<string>('');

	const landerComponents: LanderComponents = {
		'1': Lander1,
		'2': Lander2,
	};

	const SelectedLander = landerComponents[landerId];

	const { messages, pages } = useMemo(() => {
		const locale = config[market] ?? {};
		const lander = locale[landerId] ?? {};
		const version = lander[versionId] ?? {};
		return {
			messages: version.messages ?? {},
			pages: version.pages ?? [],
		};
	}, [landerId, versionId, market]);

	const currentPage: Page | undefined = pages.find((page: Page) =>
		location.pathname.includes(page.url)
	);

	const pageIndex = currentPage ? pages.indexOf(currentPage) + 1 : 0;
	const percent = Math.ceil((100 / pages.length) * pageIndex);
	const handleNext = () => {
		setDone(false);
		setMessage('');
		const currentPageIndex = currentPage ? pages.indexOf(currentPage) : -1;

		if (currentPageIndex !== -1 && currentPageIndex < pages.length - 1) {
			const nextPage = pages[currentPageIndex + 1];
			navigate(
				`/${landerId}/${versionId}/${nextPage.url}?${searchParams.toString()}`
			);
		}
	};

	useEffect(() => {
		const messageText = messages[currentPage?.url ?? ''];
		setMessage(messageText);
	}, [currentPage, messages]);

	useEffect(() => {
		// if no pages go to 404
		if (pages.length === 0) {
			window.location.href = import.meta.env.VITE_REDIRECT_URL;
		}
	}, [pages]);

	return (
		<div className="landers__wrapper w-full" style={{ height }}>
			{SelectedLander && (
				<SelectedLander
					percent={percent}
					message={message}
					done={done}
					setDone={setDone}
					handleNext={handleNext}
					landerId={landerId}
					versionId={versionId}
				/>
			)}
		</div>
	);
};
