import { useOutletContext } from 'react-router-dom';

import { RadioGroupVertical } from '@/components';
import { OutletContext } from '@/model';

import { Content } from '../content';

export const RelationshipType = () => {
	const { landerId, versionId }: OutletContext = useOutletContext();
	const relationshipType = Content[landerId][versionId].relationshipType;

	return (
		<RadioGroupVertical
			options={relationshipType?.options ?? []}
			name={relationshipType?.name ?? ''}
		/>
	);
};
