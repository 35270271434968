import { format, subYears } from 'date-fns';
import { useStateMachine } from 'little-state-machine';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { Button, Expand, GoogleSSO, InputEmail } from '@/components';
import { updateFormActionLander } from '@/hooks';
import { Gender, OutletContext, Seeking } from '@/model';
import { useValidateUserEmail } from '@/service';
import {
	handleAuthReactivation,
	LocalStorage,
	LocalStorageItems,
} from '@/utility';
import { EmailValidation } from '@/validations';

interface EmailForm {
	email: string;
}

export const Email = () => {
	const [searchParams] = useSearchParams();
	const click_id = searchParams.get('clickId') ?? '';
	const affiliate_id = searchParams.get('affiliate') ?? '';
	const source_id = searchParams.get('source') ?? '';
	const [error, setError] = useState<string>('');
	const { handleNext, landerId, versionId }: OutletContext = useOutletContext();
	const lander_id_base = `${landerId}.${versionId}`;
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<EmailForm>({
		resolver: EmailValidation,
	});

	const { actions, state } = useStateMachine({ updateFormActionLander });
	const { mutate: validateUserEmail, isPending } = useValidateUserEmail();

	const onSubmit = (data: EmailForm) => {
		const lander_id = `${lander_id_base}_typed_email`;
		LocalStorage.setItem(LocalStorageItems.LanderId, lander_id);
		LocalStorage.setItem(LocalStorageItems.Email, data.email);
		setError('');
		const { username, password, gender, city, zipCode, age } = state.lander;
		const birthday = format(subYears(new Date(), Number(age)), 'yyyy-MM-dd');
		actions.updateFormActionLander({ lander: { ...state.lander, ...data } });
		const { email } = data;
		validateUserEmail(
			{
				email,
				lander_id,
				affiliate_id,
				source_id,
				click_id,
				username,
				password,
				password_confirmation: password,
				gender: gender as Gender,
				city,
				zip_code: zipCode,
				birthday,
				seeking: gender === Gender.Male ? Seeking.Women : Seeking.Men,
			},
			{
				onSuccess: (response) => {
					handleAuthReactivation(response, handleNext);
				},
				onError: (error) => {
					const emailError = error?.response?.data?.errors?.email;
					if (emailError) {
						setError(emailError[0]);
						return;
					}
					setError('Noget gik galt. Prøv igen.');
				},
			}
		);
	};

	const handleGoogleSSO = (email: string) => {
		const lander_id = lander_id_base;
		LocalStorage.setItem(LocalStorageItems.LanderId, lander_id);
		LocalStorage.setItem(LocalStorageItems.Email, email);
		setError('');
		const { username, password, gender, city, zipCode, age } = state.lander;
		const birthday = format(subYears(new Date(), Number(age)), 'yyyy-MM-dd');
		validateUserEmail(
			{
				email,
				lander_id,
				affiliate_id,
				source_id,
				click_id,
				username,
				password,
				password_confirmation: password,
				gender: gender as Gender,
				city,
				zip_code: zipCode,
				birthday,
				seeking: gender === Gender.Male ? Seeking.Women : Seeking.Men,
			},
			{
				onSuccess: (response) => {
					actions.updateFormActionLander({
						lander: { ...state.lander, email },
					});
					handleAuthReactivation(response, handleNext);
				},
				onError: (error) => {
					const emailError = error?.response?.data?.errors?.email;
					if (emailError) {
						setError(emailError[0]);
						return;
					}
					setError('Noget gik galt. Prøv igen.');
				},
			}
		);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InputEmail {...register('email')} placeholder="Indtast din email" />
			<Expand expanded={Boolean(errors?.email) && !error}>
				<p
					className={`pandora__form--error pandora__form--error--v${versionId}`}
				>
					{errors?.email?.message}
				</p>
			</Expand>
			<Expand expanded={Boolean(error)}>
				<p
					className={`pandora__form--error pandora__form--error--v${versionId}`}
				>
					{error}
				</p>
			</Expand>
			<GoogleSSO handleGoogleSSO={handleGoogleSSO} />
			<Button loading={isPending}>Næste</Button>
		</form>
	);
};
